@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,300&display=swap');

@layer base{
    body{
        @apply font-[Raleway];
    }
    li {
        @apply px-4 cursor-pointer;
    }
}

/*Navbar component*/

.navbar{
    @apply fixed w-full h-[80px] flex justify-between items-center px-4 bg-[#0a192f] text-gray-300 ;
}

.Hamburger{
    @apply md:hidden z-10 cursor-pointer;
}

.Mobile-menu{
    @apply absolute top-0 left-0 w-full h-screen bg-[#0a192f] flex flex-col justify-center items-center ;
}

.Socials{
    @apply hidden lg:flex fixed flex-col top-[35%] left-0;
}

.Social-li{
    @apply w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300  my-2;
}

.Social-links{
    @apply flex justify-between items-center w-full text-gray-300;
}

/*Home component*/

.Home{
    @apply w-full h-screen bg-[#0a192f];
}

.container{
    @apply max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full;
}

.Home-button{
    @apply text-white border-2 px-6 py-3 my-2 flex items-center hover:bg-blue-600 hover:border-blue-600;
}

/*About component*/

.About{
    @apply w-full h-screen bg-[#0a192f] text-gray-300;
}

.About-Container{
    @apply flex flex-col justify-center items-center w-full h-full;
}

.Profilo{
    @apply sm:ml-20 rounded-full mt-4 ;
}

/*Skils component*/

.Skills{
    @apply bg-[#0a192f] w-full h-full md:h-screen text-gray-300;
}

.Skills-container {
    @apply max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full; 
}

/*Work component*/

.Work{
    @apply bg-[#0a192f] w-full md:h-full text-gray-300 ;
}

.Work-container{
    @apply max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full;
}


.content-div{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 250px;
}

.content-div:hover{
    background-image: linear-gradient(
        to right,
        rgba(112, 157, 255, 0.8),
        hsla(242, 74%, 61%, 0.8)

    )!important;
}

/*Contact component*/

.Contact{
    @apply bg-[#0a192f] w-full h-screen text-gray-300 flex justify-center items-center p-4;
}

/*Navbar component*/

.Footer{
    @apply bg-[#0a192f] w-full h-[150px] text-gray-300 flex justify-center items-center p-4;
}

/*Page404 component*/

.Page404{
@apply bg-[#0a192f] w-full h-screen text-gray-300 flex justify-center items-center p-4;
}